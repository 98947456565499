// const responsive = (breakpoint, content) => {
//     if (breakpoint === 'phone') return `@media only screen and (max-width: 37.5em) { ${content} };`    //600px
//     if (breakpoint === 'tab-port') return `@media only screen and (max-width: 56.25em) { ${content} };`    //900px
//     if (breakpoint === 'tab-land') return `@media only screen and (max-width: 75em) { ${content} };`    //1200px
//     if (breakpoint === 'big-desktop') return `@media only screen and (max-width: 112.5em) { ${content} };`    //1800px
// }

const size = {
    mobileS: '320px',
    mobileM: '375px',
    mobileL: '425px',
    tablet: '768px',
    laptop: '1024px',
    laptopL: '1440px',
    desktop: '1800px',
    phone: '37.5em',
    tabPort: '56.25em',
    tabLand: '75em',
    bigDesktop: '112.5em'
}

export const device = {
    mobileS: `(min-width: ${size.mobileS})`,
    mobileM: `(min-width: ${size.mobileM})`,
    mobileL: `(min-width: ${size.mobileL})`,
    tablet: `(min-width: ${size.tablet})`,
    laptop: `(min-width: ${size.laptop})`,
    laptopL: `(min-width: ${size.laptopL})`,
    desktop: `(min-width: ${size.desktop})`,
    phone: `(max-width: ${size.phone})`,
    tabPort: `(max-width: ${size.tabPort})`,
    tabLand: `(max-width: ${size.tabLand})`,
    bigDesktop: `(max-width: ${size.bigDesktop})`,
};