export const variables = {
  colorSecondary: "#1c3144",
  colorSecondaryDark: "#10283d",
  colorSecondaryLight: "#324a5e",

  colorPrimary: "#7ea16b",
  colorPrimaryDark: "#5b8c41",
  colorPrimaryDark2: "#417027",
  colorPrimaryLight: "#a5bf97", // login/signup button border

  colorTertiary: "#874100",
  colorTertiaryLight: "#a74b00",
  colorTertiaryDark: "#763600",

  colorBackground: "#e8e8e8", // main background color
  colorGreyLight1: "#f1f2f2", // blog background color
  colorGreyLight2: "#d6d6d6", // navlink colors
  colorGreyLight3: "#abaaaa",

  colorGreyTransparent: "rgba(51, 51, 51, .5)",
  colorGreyDark: "#585656", // used for copy
  colorGreyDark1: "#444", // used on main footer background
  colorGreyDark2: "#333", // used for secondary footer background

  colorWhite: "#fff",
  colorBlack: "#272727",

  colorTan: "#E0E0D1",

  colorSuccess: "#4AC73A",
  colorError: "#bb0000",

  // FONT
  defaultFontSize: "1.6rem",

  // GRID
  gridWidth: "114rem",
  gutterVertical: "8rem",
  gutterVerticalSmall: "6rem",
  gutterHorizontal: "6rem",
};
